import React, { useState } from "react";
import "./App.css";

function ClickableImage1({ src, alt, modalText, modalText1, rotationDegree }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Define styles with rotation based on props and state
  const imageStyle = {
    transform: `rotate(${
      isHovered ? rotationDegree.hover : rotationDegree.normal
    }) scale(${isHovered ? 1.1 : 1})`,
    transition: "transform 0.2s ease, width 0.2s ease",
    cursor: "pointer",
  };

  return (
    <div>
      <img
        className="w-96"
        src={src}
        alt={alt}
        style={imageStyle}
        onClick={toggleModal}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {isModalOpen && (
        <div className="modal" onClick={toggleModal}>
          <div className={"modal-content-both"}>
            <span className="close-button" onClick={toggleModal}>
              &times;
            </span>
            <div className="flex flex-col justify-around">
              <p className="text-blue-700">{modalText}</p>
              <p className="text-purple-700">{modalText1}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClickableImage1;
