// App.js
import React from "react";
import "./App.css";
import ClickableImage from "./ClickableImage";
import ClickableImage1 from "./ClickableImage1";
import stories from "./stories";

function App() {
  return (
    <div classname="app-bg">
      <h1 className="text-8xl text-center font-bold underline">
        IT'S WHAT? IT'S YOUR BIRTHDAY WHOOP WHOOP!
      </h1>
      <div className="flex flex-row bg-gradient-to-b from-red-200 to-green-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/skeleton.png`}
          alt="Skeleton"
          modalText={stories.skele}
          author={"marc"}
          rotationDegree={{ normal: "20deg", hover: "-20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/lady_liberty.png`}
          alt="Lady Liberty"
          modalText={stories.lady_liberty}
          author={"marc"}
          rotationDegree={{ normal: "-5deg", hover: "-20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/hands.png`}
          alt=""
          modalText={stories.hands}
          author={"mere"}
          rotationDegree={{ normal: "30deg", hover: "560deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/ninacity.png`}
          alt=""
          modalText={stories.ninacity}
          author={"mere"}
          rotationDegree={{ normal: "-10deg", hover: "-40deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/click.png`}
          alt=""
          modalText={stories.click}
          author={"mere"}
          rotationDegree={{ normal: "0deg", hover: "0deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-green-400 to-purple-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/scared.png`}
          alt=""
          modalText={stories.scared}
          author={"mere"}
          rotationDegree={{ normal: "-10 deg", hover: "-20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/ninadrive.png`}
          alt=""
          modalText={stories.ninadrive}
          author={"marc"}
          rotationDegree={{ normal: "-5deg", hover: "-20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/winetaste.png`}
          alt=""
          modalText={stories.winetaste}
          author={"mere"}
          rotationDegree={{ normal: "30deg", hover: "40deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-purple-400 to-orange-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/cat_photo.jpg`}
          alt=""
          modalText={stories.cat_photo}
          author={"mere"}
          rotationDegree={{ normal: "-10deg", hover: "-50deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/CLUB.png`}
          alt=""
          modalText={stories.CLUB}
          author={"marc"}
          rotationDegree={{ normal: "-25deg", hover: "35deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/dining.png`}
          alt=""
          modalText={stories.dining}
          author={"marc"}
          rotationDegree={{ normal: "30deg", hover: "-40deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/museum.png`}
          alt=""
          modalText={stories.museum}
          author={"mere"}
          rotationDegree={{ normal: "25deg", hover: "35deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-orange-400 to-blue-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/florencebridge.png`}
          alt=""
          modalText={stories.florencebridge}
          author={"marc"}
          rotationDegree={{ normal: "-30deg", hover: "20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/florencewmarc.png`}
          alt=""
          modalText={stories.florencewmarc}
          author={"marc"}
          rotationDegree={{ normal: "25deg", hover: "-15deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/guitar.jpg`}
          alt=""
          modalText={stories.guitar}
          author={"mere"}
          rotationDegree={{ normal: "15deg", hover: "25deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-blue-400 to-yellow-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/greenjuice.png`}
          alt=""
          modalText={stories.greenjuice}
          author={"mere"}
          rotationDegree={{ normal: "-20deg", hover: "40deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/green.png`}
          alt=""
          modalText={stories.green}
          author={"mere"}
          rotationDegree={{ normal: "25deg", hover: "-35deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/holdhands.png`}
          alt=""
          modalText={stories.holdhands}
          author={"marc"}
          rotationDegree={{ normal: "35deg", hover: "-20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/other_half.jpg`}
          alt=""
          modalText={stories.other_half}
          author={"mere"}
          rotationDegree={{ normal: "35deg", hover: "-20deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-yellow-400 to-green-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/juice.png`}
          alt=""
          modalText={stories.juice}
          author={"mere"}
          rotationDegree={{ normal: "-20deg", hover: "35deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/knoxville.png`}
          alt=""
          modalText={stories.knoxville}
          author={"mere"}
          rotationDegree={{ normal: "35deg", hover: "-20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/pose.png`}
          alt=""
          modalText={stories.pose}
          author={"mere"}
          rotationDegree={{ normal: "-20deg", hover: "35deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-green-400 to-orange-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/ben_ninalton.png`}
          alt=""
          modalText={stories.ben_ninalton}
          author={"mere"}
          rotationDegree={{ normal: "25deg", hover: "-30deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/lib4.png`}
          alt=""
          modalText={stories.lib4}
          author={"mere"}
          rotationDegree={{ normal: "-30deg", hover: "25deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/camden_kiss.jpg`}
          alt=""
          modalText={stories.camden_kiss}
          author={"mere"}
          rotationDegree={{ normal: "25deg", hover: "-30deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/formal.png`}
          alt=""
          modalText={stories.formal}
          author={"marc"}
          rotationDegree={{ normal: "-20deg", hover: "20deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-orange-400 to-pink-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/merebed.png`}
          alt=""
          modalText={stories.merebed}
          author={"mere"}
          rotationDegree={{ normal: "30deg", hover: "40deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/down.png`}
          alt=""
          modalText={stories.down}
          author={"marc"}
          rotationDegree={{ normal: "-35deg", hover: "45deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/VICTIM.png`}
          alt=""
          modalText={stories.VICTIM}
          author={"marc"}
          rotationDegree={{ normal: "25deg", hover: "35deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/lib2.png`}
          alt=""
          modalText={stories.lib2}
          author={"marc"}
          rotationDegree={{ normal: "20deg", hover: "30deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/original freezo.png`}
          alt=""
          modalText={stories.originalFreezo}
          author={"mere"}
          rotationDegree={{ normal: "15deg", hover: "25deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-pink-400 to-green-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/outside.png`}
          alt=""
          modalText={stories.outside}
          author={"marc"}
          rotationDegree={{ normal: "10deg", hover: "20deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/party.png`}
          alt=""
          modalText={stories.party}
          author={"mere"}
          rotationDegree={{ normal: "0deg", hover: "10deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/pine.png`}
          alt=""
          modalText={stories.pine}
          author={"mere"}
          rotationDegree={{ normal: "-5deg", hover: "-45deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-green-400 to-blue-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/car.png`}
          alt=""
          modalText={stories.car}
          author={"mere"}
          rotationDegree={{ normal: "20deg", hover: "-30deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/pippies.png`}
          alt=""
          modalText={stories.pippies}
          author={"mere"}
          rotationDegree={{ normal: "-10deg", hover: "-50deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/pontevecchio.png`}
          alt=""
          modalText={stories.pontevecchio}
          author={"marc"}
          rotationDegree={{ normal: "-15deg", hover: "85deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/pose2.png`}
          alt=""
          modalText={stories.pose2}
          author={"mere"}
          rotationDegree={{ normal: "-25deg", hover: "15deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-blue-400 to-purple-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/poseinside.png`}
          alt=""
          modalText={stories.poseinside}
          author={"mere"}
          rotationDegree={{ normal: "30deg", hover: "40deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/yay2.png`}
          alt=""
          modalText={stories.yay2}
          author={"mere"}
          rotationDegree={{ normal: "-10deg", hover: "0deg" }}
        />
        <ClickableImage1
          src={`${process.env.PUBLIC_URL}/imgs/worstphoto.png`}
          alt=""
          // modalText={stories.worstphoto}
          // modalText1={stories.worstphoto1}
          modalText="WELL FUCK YOU TOO"
          modalText1="ignore him. love you!!"
          rotationDegree={{ normal: "-5deg", hover: "5deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/pile_of_books.jpg`}
          alt=""
          modalText={stories.pile_of_books}
          author={"marc"}
          rotationDegree={{ normal: "25deg", hover: "35deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-purple-400 to-amber-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/sipsip.png`}
          alt=""
          modalText={stories.sipsip}
          author={"marc"}
          rotationDegree={{ normal: "15deg", hover: "25deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/gray.png`}
          alt=""
          modalText={stories.gray}
          author={"marc"}
          rotationDegree={{ normal: "15deg", hover: "-35deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/hammock.jpg`}
          alt=""
          modalText={stories.hammock}
          author={"mere"}
          rotationDegree={{ normal: "15deg", hover: "25deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/sleep.png`}
          alt=""
          modalText={stories.sleep}
          author={"marc"}
          rotationDegree={{ normal: "10deg", hover: "20deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-amber-400 to-yellow-400">
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/big_check.png`}
          alt=""
          modalText={stories.big_check}
          author={"marc"}
          rotationDegree={{ normal: "5deg", hover: "15deg" }}
        />
        <ClickableImage
          src={`${process.env.PUBLIC_URL}/imgs/thumbsup.png`}
          alt=""
          modalText={stories.thumbsup}
          author={"mere"}
          rotationDegree={{ normal: "0deg", hover: "10deg" }}
        />
      </div>
      <div className="flex flex-row justify-around items-center bg-gradient-to-b from-yellow-400 to-pink-400">
        <ClickableImage1
          src={`${process.env.PUBLIC_URL}/imgs/race.png`}
          alt=""
          modalText={stories.racemarc}
          modalText1={
            "nina i loooooooove you nina i love youj so so so sos os so so much words cannot express how i feeel about you how glad i am to have you in my life and how dearly i wish for our relationship to do nothing but change and evolve to conform to the changes in our own individualities as we continue to grow next to each other. you mean the world to me and always will. also wouldn't it be reall funny if my shirt said i love meth haha just kidding that's a little joke to warm the audience up. the audience is you and i want to spend eeeeevery minute of every day making each other laugh and smile and and and and and and !!!!!!! language fails!! languge fails. remember how in toki pona 'olin' meant love? they were talking about US. olin e sina, nina!!! <3<3<3<3<3<3<3<3<3 and to many more"
          }
          rotationDegree={{ normal: "25deg", hover: "35deg" }}
        />
      </div>
      <h3 className="text-4xl text-right">
        &lt;3 from <span style={{ color: "blue" }}>marc</span> and{" "}
        <span style={{ color: "purple" }}>meredith</span>~~
      </h3>
    </div>
  );
}

export default App;
