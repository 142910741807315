const stories = {
  empty: "",
  skele:
    "This was from that one night, a very spooky night on halloween night where I joined a skeleton gang. But anyways remember when we are really freaking bored on Halloween night? Strolling hall to hall waiting in line for shitty drinks in Luke's suite's party?? Yeah so let's just say we weren't having a good time until Meredith and Marc decided to put on Skeleton makeup using black lipstick. FUCK, wait that was wayyyy before Luke's suite's party with the lame drinks. Ok nevermind, we put make up on, and then Nina Mindel walks into Meredith's room and she's like 'um guys what the heck is going on here'. We explained to Nina that now, this Halloween night, we had gone skeleton mode. After we had explained our plans, the Meredith/Marc skeleton duo become a trio :) aw. Off we went into west hall, transmutating Alton, then Ben, or was it Ben then Alton? I had drank alot I forgot! So, as skeletons do, we ran deep into the woods listening to a spotify blend of all of our favorite music. In the forest we find a tower, and some of us piss off of it which is awesome, however at one point the quintuplet of skeletons reach the top of the tower and just fucking hang really hard, like seriously that shit was so chill. After the tower, the BONER FREAKS (our gangs name), stumble upon a big net: we lay down for some much needed rest. Nothing much happens after, however, Alton the Skeleton (rhymes) definitely did scare us come on Alton that's not cool >:(",
  car: "us and camden and ben on that night that we all got ramen wasn't that a caraaaazzyyyy night!! tiny trader joes the tiniest in the whole world its really fucked up that they make all of those tiny farmers make all of the tiny organic nonGMO foods for the tiny trader joes. i remember we were actually there to buy supplies for our big new york trip the next day!! we hadn't even eloped yet in this photo so if we're really thinking about it this is kind of one of the last non-forever-committed photos we have",
  click: "Not on me silly!!!! On the photos!!!!!!!!!!!",
  CLUB: "WELCOME TO THE MY CLUB MY NAME IS NINA MINDEL AND THIS IS MY CLUB! WELCOME!",
  dining:
    "So this was one of those nights where we went out to eat somewhere. This particular somewhere is Acqua Al 2, known for their very famous blueberry steaks. So Nina pretty much what went down is that we shared some pasta, and two steaks: one blueberry and one balsamic (from the region of modena, very important!). We did in fact have good conversation under the groin vaulted ceiling (look it up :) https://en.wikipedia.org/wiki/Groin_vault). Marc bought a glass of red wine which paired excellently with his steak because it constrasted the sweet tendancy of the steak BUT IT DID NOT GO GOOD WITH BLUEBERRY BECAUSE YOU NEED TO PAIR SWEET WINES WITH SWEET FOODS REMEMBER THIS!!!",
  down: "Um hello why are you so tall?",
  florencebridge:
    "Average CONAD shopper, yeah, we're just like you, we shop at CONAD too. You're like 'I got my wine and ready to dine from CONAD (HARRY POTTER)' which explains why you're so happy in this photo :)",
  florencewmarc:
    "Ok now this is just a picture of two sick ass motherfucking individuals damn! This photo is in front of the Pallazo di baddasses holy shit! Ok for real the statues behind us are probably some important guys, Leonardo and Craig lets say, they stay posted as scouts to look for SICKOS (in a good way) like us who they can recruit, let's just say I think we're in ;}",
  formal: "White on white on White on white!",
  gray: "LOOK AT THIS PHOTOGRAPH...",
  green:
    "this is us at a 2n party!!! on the wall is you and me in a minecart and it says me+nina holding hands i think this was valentines day and you were supposed to draw you with your date?? anyway i think this was the early stages of us being fucking insufferable as brooklyn would say (nicely! i actually kind of liked that she called us that) because i remember being alittle nerbous to show you. i was like omg is she going to think im weird is this weird and then it WASN'T and we've been doing stuff like that forever and ever since then the end",
  greenjuice:
    "our venice date!! i had so much fun splitting a platea pasta and a panna cotta with my gorgeous esteemed refined beautiful date. and getting tipsy off of half a glass of wine each!!! we are so girl! such cheap dates that's such good news for us, and maybe we found a wine that nina likes?? it's called prosecco btw, its like a sparkling wine thing instead of a full meatyearthy regular wine or whatever marc would call it god what a tool about wines (haha lol im just kidding i like that he does the wine thing i think its adorable he's like all fancypants man. i wonder what he thinks about prosecco ME PERSONALLY i like it better but i thnk wine snobs would like it less) anyway i hope you liked it toooo i loved treating you you should tell me if everyone liked the gifts that you got them in italy AND in venice (haha again! little joke for me! venice is IN italy isn't that silly to say 'and' there! haha well. we have fun here) i want to know what they think!! the motorcycle keychain for alton is what im thinking of because we bought it immediately pre-dinner",
  hands:
    "Cherry wine!!! Hozier concert that we went to that was so beautiful and moving and we saw those two ladies that were older and still best friends and they took our photo and you took theirs and he’s LITERALLY in a cup of cherry wine while singing cherry wine the lights are allegorical and so was the entire night",
  holdhands:
    "Ever feel like a creep when you walk behind two girls in the middle of the night and start taking pictures?",
  juice:
    "speaks for itself. not a lot to say here. god you're so cute i'm glad i know the number one sex god in the WORLD",
  knoxville:
    "we finally made it!!! we were literally THERE go vols!! i remember this trip so fondly i'm glad you got to meet my friends they all love you and ask what you're up to every time i see them. i looooooved being obnoxious and taking photos with every sign we saw. also!! a trip full of both lessons AND blessings i was so so proud of you for trusting yourself and finally making the big camilo cancel. my PARENTS still ask about you too they think you're a fantastic inflence on me and just a nice young lady overall!!! i liked driving so much with you i liked living with you i liked realizing at the end that we had sticky spots but i still wanted you to stay longer. i genuinely haven't felt about anyone else the way i feel about you nina i'm sure to my core that i'll never find anyone ever forever in the whole world in my whole walk of life that i get along with the way i get along with you",
  lady_liberty:
    "See to shining see, GOD BLESS AMERICA. Also sick fucking cup torch prop who told you to do it like that? BEST LADY LIBERTY AT THE 2023 HALLOWEEN PARTY! (there were multiple btw)",
  lib2: "BWAWH ;{",
  lib4: "we are dancing!! so inthemoment so joyful. marc took all of the other opportunities to say something about the halloween costumes so ill just say cole and hot girlfriend can rot in hell i have the hottest girlfriend around and we obliterated this costume so thouroughly its kind of fucked up that everything worked out so well. like they probably named new york with is in mind, with the idea that we would one day need to make a statement so artistically sound and creatively dense that it blows the socks, shoes, and toenails off of anyone who so much as takes a cursory glace at one of the photos, much LESS tries to listen to our explanation about the lesbian kissing in the heart and the red solo cup in the torch?? American party and lovingyourbestfriend culture to our CORE",
  merebed:
    "IF YOU ARE NOT NINA AVERT YOUR EYES THIS PHOTO IS FOR NINA ONLY ok now that we're alone i'll whisper a secret to you: i really like laying like this. it's so comfortable and i don't have casual physical intimacy with anyone like i have it with you... i'm so lucky to have you in my life",
  museum:
    "our first trip together!! new york city the big apple we fell in love with each other and with the city and with each other IN the city and was this the trip where we decided we were going to move back and live there together? or did that come later? anyway here's what i remember of the trip - you were driving in downtown new york and i thought it was soooo cooool how you were keeping such a chill head even though people were being ASSHOLES and i kept telling your dad we were going to a math museum and you thought i was trying really way too too hard to be funny but i was actually just making a point about the math museum across the street that we ALSO wanted to visit but we couldn't becasue the sex museum had GAMES which we weren't expecting and we ran out of time but you won the sex god crown and then used it in polaroids in your room which was super cute even though the juju in the room ended up being cursed. also i won the sperm which i put in your car and it might even still be there?? also we got MARRIED and we both forgot to celebrate our one year together which is a little poetic i think because every day is like my one year anniversary with you because we are so honeymoon phase. november <3 our wedding date!! what a beautiful trip i loved meeting your mom and seeing your house and walking around and learning (superficially) THE CITY OF OUR DREAMS together (not in the deep intimate way we will later get to know the city (when we live together (and have lifetime memberships to the sex museum except maybe not it kind of seemed like a one time thing)))",
  ninacity:
    "the most beautiful girl in the WORLD the most BEAUTIFUL girl in NEW YORK CITY the ONE girl most suited to the CITY LIFESTYLE it looks so good on the CITY STYLE GIRL she's BEAUTIFUL!!!",
  ninadrive:
    "Ok this one was a doozy, we literally drove to run. I mean who drives to go on a run what is this like whattttt? We drove two hours to run for two hours, the math doesn't add up you tell me if the math adds up beacuse for me it sure doesn't add up the way it should. ANYWAYS, so we DRIVE TO RUN, Nina drove straight shot down the pike to the running spot where we ran the MUD RUN WHOOOO. Ok maybe driving to run does make sense because we killed that shit there was like 100000 obstacles all in the mud too and with obstacles that you had to go over. Fun fact this photo is a post run photo because I'm realizing I (marc) was wearing my pink lounge pajama shirt which indicates a post run photo.",
  o: "",
  originalFreezo:
    "WE are the original freezos. ice cold. chill. creamy. coned on bottom. whipped on top. big ol smiles but only for those that truly earn it. cold to those that don't deserve it. hearts of gold. fits fire. i don't know i can't think of a way that we're super different than freezo do yuou want to go out and get a mascot suit w me sometime i can be the cone and you can be the head we'll wear it like one of those horse costumes or maybe you on my shoulders to that we can be mr freezo's actual height: about the same height as you on my shoulder which. my best guiess would be like 8, 8.5 feet? what do you think how long are you, torso to tip (of head. not tip of anything else. i wouldn't ask torso to tip if i were asking for another measurement id probably ask tainttotip which is where you get the real info. anyway i love you you're really cool)",
  outside:
    "We outside this one FRRRRR, great lawn style. Um meredith is upsidedown for some reason lolol! kinda shows how crazy the great lawn can get in fact, the craziness is exacerbated by the rock hands you're throwing up that is wild. So actually I remember this day because I had to go to the printer like 8 times and I'm pretty sure we played volleyball which was fun, FUN CUT SHORT BECAUSE WE ALSO HAD TO WORK (I'm pretty sure I was doing discrete math booo but we love Sarah!! <333). Looking back at this photo only tells me one thing, there's no reason that we shouldn't be out there on the green together ",
  outside2:
    "Ok so pretty much the same thing but now from another angle that's how it works when someone takes a picture from one side and then another person takes another picture from the other side if this doesn't make sense make sure you look at the first picture where we're on the blanket and then come back to this <------- READ LAST 15 WORDS FIRST",
  party:
    "WHAT A COOL COSTUME and you kind of matched with alton?? arts and crafts couple how cool is that!! anyway i really like this pose to be honest i might start copying you sincerely flattery and all that its just sooo coy and confident and flirty and fun. words i would all use to describe you anyway so its a pretty perfect pose to communicate everything that you are ",
  pine: "MIHIR HOUSE and the day that we talked on that person's DOCK and you told me about your old asshole best friend and her weird DICK MEASURING CONTESTS she would stat by insulting you after already being a GUEST i felt like i learned so much about you on this day also we went to MIHIRS HOUSE was he freaked out about this? i feel like i never heard the end of this story i want to know how he reacted. or perhaps how the genetically losers groupchat reacted was he as freaked out as I would be if someone sent me a photo with my actual motherfucking mailbox",
  pippies:
    "meeting your mom!!! meeting your dogs!!!!! on ginger on suzie this was a fun ass trip. cheap maggie's included walking the dogs and finding a yard sale INCLUDED new york trip INCLUDED wow we really had a packed weekend anyway i loooooooove your dogs they are so cute. the thing i think about most from visitng your home is the wall with all the cards you've written to your mom over the years it was so caring and loving. it ",
  pontevecchio:
    "Little do they know that Ponte Vecchio is actually the oldest bridge in Florence MAYBE but probably true. What's funny here is that in the background you can see the scary carnaval masks and also Sam's epic yellow bag that was holding EVERYTHING from that day. Just as a reminder we had pasta beforehand and I'm pretty sure we walked like 13 miles that's insane who walks that much in one day, um, yeah we do!",
  pose: "FIRST TIME DRINKING hedonistic in florence. first series of photos that she doesn't remember taking what a fantastic thing that all of those photos came out so well!!! a beauty in her natural habitat (full of joy and exuberance, running through the streets,,,,)",
  racemarc:
    "So it would be funny if meredith's shirt said I hrt meth but let me know what you think about that one. This photo is the definition of grit, perseverance, resilience, determination, tenacity, resolve, fortitude, steadfastness, endurance, courage, strength, toughness, pluckiness, indomitability, gutsiness, and spunk (what the fuck is spunk). Remember, this was the run that we drove to run I mean who does that, um yeah again, we do! But ok here's the problem Nina, we had to find an image to be at the bottom of the page to conclude the Entire Nina Timeline (ENT) and mere and I said that it needs to have all of us and I said that the race finish photo would be appropriate since it's the end of the race and the end of the ENT. But you know what it's not really the end of anything! It's only the beginning, because next semester is going to also include the ENT, which means that the current ENT is in fact not the entirety of the NT but only a small part (probably should change the name then to Partial Nina Timeline (PNT)). POINT IS, Nina we still have so much more to do together and I'm so freaking excited because you are the funniest person I know and sometimes people like me need a good laugh. Happy (1 day late, grrrr) Birthday.",
  racemere: "blowing his spot up rn, you weren't supposed to see this i d",
  scared:
    "fuckedup little tiny car why would they make it like that. only an imp could sit in that car only a goblin could drive it. we are neither that's why you look so SCARED",
  pose2: "SAME POSE AGAIN what the whaaaaat!!!!!",
  poseinside:
    "soooo much better than a prisha pose. god you hit this shit so well so easily so effortlessly",
  race: "So it would be funny if meredith's shirt said I hrt meth but let me know what you think about that one. This photo is the definition of grit, perseverance, resilience, determination, tenacity, resolve, fortitude, steadfastness, endurance, courage, strength, toughness, pluckiness, indomitability, gutsiness, and spunk (what the fuck is spunk). Remember, this was the run that we drove to run I mean who does that, um yeah again, we do!",
  scared:
    "fuckedup little tiny car why would they make it like that. only an imp could sit in that car only a goblin could drive it. we are neither that's why you look so SCARED",
  sipsip:
    "This was the orgasm LOL UP TOP! Ok so the thing is that they don't keep the passionfruit seeds in the drink and I actually went inside to ask the bar tender lik ehey man what is your fucking deal where are my passionfruit seeds and he was like well we just throw them away because people don't like them and I almost lost my shit in middle of the bar (this was the bar where I was talking to the bar tender about the passion fruit drink). In all, Nina and Meredith shared two of these drinks (with a funny name haha) and that's where the wild crazy Florentine night began.",
  sleep:
    "Ok I'm gonna give the meta Marc perspective, I hear love island rip off 99 going on and then over time the volume gets lower and lower, then I hear nothing. I walk in and see mere and nina sleeping like this, AND I DONT REMEMBER IF I SAW IT, but mere said that she was pretending to sleep to get this photo which is really funny also it would have been weird if I just took a picture of you guys sleeping so thank you for taking one for the team meredith.",
  thumbsup:
    "i miss isabel :( i miss how sodium was when isabel ran it and also when everyone was chill!! not that i didnt ALSO like the coup (the one that's pronounced like 'coo' like where jonas rebelled and tried to carry isabel's head out on a stick like brutal like murder) that was kind of fun but there was for sure a golden age, at least in terms of my experience with sodium and im soo glad we got to share that with each other and that it didn't all vanish into the ether of singular unshared memories",
  winetaste: "PINOOOOOOOOOOOOO 😈😈😈😈😈😈😈😈😈😈😈😈😈😈😈😈",
  worstphoto: "",
  yay: ":D",
  big_check:
    "'MONEY, ITS A GAS GRAB THAT CASH WITH BOTH HANDS AND MAKE A STASH', holy shit I did not know my friend Nina Mindel was freaking loaded uhhh hello girl boss fr. Ok but how do you cash that in do you walk into the bank with the comically large check? What if your car is too small (maybe if you drive a smart car), do you fold it in two in that case, and if you fold it can you even cash it in? Man I wish I had these problems, well I guess it pays to win hackathons YOU FREAKING SMARTIE PANTS",
  pile_of_books:
    "'erm hello would you mind helping me with my books, there are just so many of them and let me tell you this isn't even The Magic Treehouse type shit either this is big girl stuff, errr Noam Chomsky, ever heard of him?? ('he's literally a piece of shit marxist for real, fuck chomsky'-marc) Anyways thanks for helping me with my books (how do you even help someone with their books what does that even mean, can you help me read my books what am I a freaking baby?)'",
  VICTIM:
    "I think this is what Juliet felt like when she knifed herself, two people who are so in love that they can't be together. But Nina, I think we all know that Juliet loved Romeo, so why are you knifing yourself? who's the lover in the picture (not the literal picture I'm talking about the metaphorical picture, like, 'He wasn't in the picture anymore', do you get it? text me if you don't +1 (949)-363-3118, preferably whatsapp actually so I don't have to pay SMS international fees). So yeah, everyone knows from Romeo and Juliet that when a beautiful girl knifes herself (yeah you, MY FRIEND NINA IS BEAUTIFUL!) that there must be a lover in the photo (maybe Meredith because I know you kissed her HA!) but let me know if I'm right my number is +1 (949)-363-3118 but use whatsapp for reasons I explained last sentence !",
  yay2: "REUNITED ONCE AGAIN AND ANGELS SANG AND JESUS WEPT AND WE TOOK PHOTOS BECAUSE WE MUST if there's one thing we're going to do its take photos of a beautiful moment and what moment was ever beautiful if not this one",
  ben_ninalton:
    "blowing his spot up rn, you weren't supposed to see this i don't think. but mwah mwah!! hold them and make them kiiiiiisss ooooooooo",
  camden_kiss:
    "mwah!! kiss kiss!! AND he'll be on campus with us next semester the gang is STILL all together isn't that beautiful?? he'll come visit us in NY sometime surely, this isn't the end of the early college shenanigans by far but . i really like this photo <3 so sweet to be recieving love from two of the nicest fattest asses on onlin campus and the eastern seaboard as a whole",
  cat_photo:
    "omg the THIS IS HER photo. this is not my nina this is her photo obviously that is the one that's on my phone lockscreen because its how i start my conversations hey nice to meet you what do you care about more than anything in the world oh you want to know my answer?? oh obviously hey check this out BAM photo of me and nina looking so happy and cute together. anyway it seems like this is that for alton??? or something i don't completely understand the significane of the cat picture to you but it's soooo cuteadorable i thought we had to include it even though neither marc nor i were there and marc has never seen it before and you go eughhhh every time its brought up it MAKES THE CUT sorryyyyyyyyyy",
  guitar:
    "you have MASTERED standing there and looking like really cool. also you're mastering guitar!!! it's so cool that you have the acoustic guitar songs that you do AND you don't need a stinky hinge man to teach them to you DUH but it was always you all along. the call was coming from inside the house she was simply using her guitar coollooking skills and purported lack of prowess as a SNARE a TRAP which men are liable to fall into because of course they are i would fall for any snare that looked that good too",
  hammock:
    "we r chilling sooooo hard here. what an uncomfortable hammock to be in though i don't remember like anything about this day except that the hammock was super duper uncomfortable and also maybe that we had just come from lunch with richard and you were there for the last like 10 minutes of the lunch with richard and he was doing the whole woahahhhh you guys talk too fast and too witty and too cool for me and we for the first time were like yeah i GUESS some people cant handle the heat!!!! can't handle the kitchen can't handle this fire!!!!!! the ah ah oh ah hot!!! that's too hot!!! oof whoof my hand my poor little pride in the face of better conversationalists!!!!!!!!!!!! i cant take it id better get out of the kitchen whoops lol whoops",
  other_half:
    "refer to the above <3. as written <3. like she said (she is the wall) <3.",
};

export default stories;
